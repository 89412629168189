




























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import MoodItem from "@/models/MoodItem";
import WeekDatePicker from "@/components/global/WeekDatePicker.vue";

@Component({components: {WeekDatePicker}})
export default class DatePicker extends Vue {

    @Prop({required: true, default: ''})
    public readonly value!: string;
    private dateValue: string = '';

    @Prop({required: true, default: 0})
    private dateType!: number;

    private dateMenu: boolean = false;

    mounted() {
        this.openOnChange();
    }

    @Watch("value")
    openOnChange() {
        this.dateValue = JSON.parse(JSON.stringify(this.value));

    }

    constructor() {
        super();
    }

    public get formattedDate() {
        let dateString = this.dateValue;
        let formattedDate = '';
        if (!dateString) {
            dateString = MoodItem.getFormattedDate(new Date());
        }
        switch (this.dateType) {
            //day
            case 0: {
                formattedDate = this.$d(new Date(dateString), 'date');
                break;
            }
            //month
            case 2: {
                formattedDate = this.$d(Date.parse(dateString), 'month');
                break;
            }
            //year
            case 3: {
                //statements;
                formattedDate = this.$d(new Date(dateString), 'year');
                break;
            }
        }

        return formattedDate;
    }

    private get maxDate(): string {
        return MoodItem.getFormattedDate(new Date());
    }

    @Watch("dateValue")
    private dateValueChanged(newValue) {
        this.$emit("input", newValue);
    }

    private clearDateValue() {
        this.dateValue = "";
        this.$emit("input", this.dateValue);
    }

    private get datePickerType() {
        if (this.dateType == 2) {
            return 'month';
        }
        if (this.dateType == 3) {
            return 'year';
        }
        return 'date';
    }
}
