



























































import {Component, Vue} from 'vue-property-decorator';
import MoodItem from "@/models/MoodItem";
import i18n from "@/plugins/i18n";
import {globalEventBus} from "@/main";
import {MoodType} from "@/models/MoodType";

@Component
export default class SeriesDialog extends Vue {
    private openDialog: boolean = false;
    private date: Date = new Date();
    private entries: MoodItem[] = [];

    constructor() {
        super();
    }

    mounted() {
        globalEventBus.$on("refreshMoodItems", this.refresh);
    }

    destroyed() {
        globalEventBus.$off("refreshMoodItems", this.refresh);
    }

    private showMoodItem(item: MoodItem): void {
        this.$emit("showMoodItem", item);
    }

    public getDateTime(item: MoodItem): string {
        if (item?.date) {
            if (item.time) {
                const parsedDate = new Date(item.date + " " + item.time);
                return i18n.d(parsedDate, "dateTime");
            } else {
                let parsedDate = new Date(item.date);
                return i18n.d(parsedDate, "date");
            }

        }
        return '';
    }

    public async refresh() {
        const dateString = MoodItem.getFormattedDate(this.date);
        await this.$webApi.readMoodEntriesRange(dateString, dateString,undefined).then((entries) => {
            if (entries!.data) {
                this.entries = entries.data.filter((item) => item.date === dateString).sort((a, b) => {
                    return this.getDateTimeNumber(a) - this.getDateTimeNumber(b);
                });
            }
        });
    }

    public async showDialog(date: Date) {
        this.date = date;
        const dateString = MoodItem.getFormattedDate(this.date);

        const result = await this.$webApi.readMoodEntriesRange(dateString, dateString,undefined).then((entries) => {
            if (entries!.data) {
                this.entries = entries.data.filter((item) => item.date === dateString).sort((a, b) => {
                    return this.getDateTimeNumber(a) - this.getDateTimeNumber(b);
                });
                if (this.entries.length == 1) {
                    this.$emit("showMoodItem", this.entries[0]);
                } else {
                    this.openDialog = true;
                }
            } else {
                this.openDialog = false;
            }
        });
    }

    closeDialog(): void {
        this.openDialog = false;
    }

    private getDateTimeNumber(item: MoodItem): number {
        if (item.time) {
            return Date.parse(item.date + " " + item.time);
        }
        return Date.parse(item.date);
    }

    private getIcon(moodValue: number): string {
        const diarySettings = this.$store.getters.diarySettings;
        if(diarySettings.moodType === MoodType.Thumb) {
            if (moodValue >= 3) return 'mdi-thumb-up';
            else return 'mdi-thumb-down';
        }else if (diarySettings.moodType === MoodType.Mood5){
            if (moodValue === 5) return 'mdi-emoticon-excited';
            else if (moodValue === 4) return 'mdi-emoticon-happy';
            else if (moodValue === 3) return 'mdi-emoticon-neutral';
            else if (moodValue === 2) return 'mdi-emoticon-sad';
            else return 'mdi-emoticon-cry';
        } else {
            if (moodValue >= 4) return 'mdi-emoticon-happy';
            else if (moodValue === 3) return 'mdi-emoticon-neutral';
            else return 'mdi-emoticon-sad';
        }
    }
}
