


























































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import MoodItem from "@/models/MoodItem";
import ColorPicker from "@/components/global/ColorPicker.vue";
import moment from "moment-mini";
import {MoodType} from "@/models/MoodType";

@Component({
    components: {ColorPicker}
})
export default class MoodaryDialog extends Vue {
    private loading:boolean = false;
    private dialogIcon: string = 'mdi-plus-circle-outline'
    private pictureFile: any = {};
    private fileLoading: boolean = false;
    private formattedDate: string = '';
    private formattedTime: string = '';
    private showTime: boolean = false;
    private showDate: boolean = false;
    private newEntryDialogTitle: string = this.$tc('moodary.entry.newTitle');
    private readonly: boolean = true;
    private readonly item: MoodItem;
    private isNewEntry: boolean = false;
    private openDialog: boolean = false;
    private maxDate:string = '';
    private minDate:string = '';

    private get ratingNumber(): number {
        const diarySettings = this.$store.getters.diarySettings;
        if(diarySettings.moodType === MoodType.Mood5 ){
            return 5;
        }
        if(diarySettings.moodType === MoodType.Thumb) {
            return  2;
        }
        return  3;
    }
    private get timeFormat(): string {
        if (this.$store.getters.lang === 'en') {
            return "ampm";
        }
        return "24hr";
    }

    constructor() {
        super();
        this.item = MoodItem.create(this.$store.getters.diarySettings.moodType)
    }

    mounted() {
        this.setFormattedDate();
        this.setFormattedTime();
    }

    @Watch("item.date")
    private setFormattedDate(): void {
        let parsedDate = new Date(this.item.date);
        this.formattedDate = this.$d(parsedDate, "date");
    }

    @Watch("item.time")
    private setFormattedTime() {
        if (this.$store.getters.lang === "en") {
            if (this.item.time) {
                const H = +this.item.time.substr(0, 2);
                let h = (H % 12) || 12;
                const ampm = H < 12 ? "AM" : "PM";
                this.formattedTime = (h < 10 ? "0" + h : h) + this.item.time.substr(2, 3) + ampm;
            } else {
                this.formattedTime = this.item.time || "";
            }
        } else {
            if (this.item.time && this.item.time.length > 5) {
                this.item.time = this.item.time.substr(0, 5);
            }
            this.formattedTime = this.item.time || "";
        }
    }

    public viewItem(item: MoodItem,minMax:{start:{date:string},end:{date:string}}|undefined = undefined): void {
        this.newEntryDialogTitle = this.$tc('moodary.entry.viewTitle');
        this.showDialog(item, false, false,minMax);
    }

    public editItem(item: MoodItem,minMax:{start:{date:string},end:{date:string}}|undefined = undefined): void {
        item.moodValue = this.getValue(item.moodValue);
        this.newEntryDialogTitle = this.$tc('moodary.entry.editTitle');
        this.showDialog(item, false, false,minMax);
    }

    public newItem(item: MoodItem, minMax:{start:{date:string},end:{date:string}}|undefined = undefined): void {
        this.newEntryDialogTitle = this.$tc('moodary.entry.newTitle');
        const diarySettings = this.$store.getters.diarySettings;
        item.color = item.color ? item.color : this.getColor(item.moodValue);
        if (!diarySettings.presetTime) {
            item.time = '';
        }
        this.showDialog(item, true, false, minMax);
    }
    private getColor(moodValue:number) : string {
        const moodTypeSettings = this.$store.getters.diarySettings.moodTypeSettings;
        switch (moodValue){
            case 5:
                return moodTypeSettings.five.color;
            case 4:
                return moodTypeSettings.four.color;
            case 3:
                return moodTypeSettings.three.color;
            case 2:
                return moodTypeSettings.two.color;
            case 1:
                return moodTypeSettings.one.color;
        }
        return '';
    }

    private setItemValues(item: MoodItem): void {
        try{
            this.loading = true;
            this.pictureFile = null;
            this.item.id = item.id;
            this.item.date = item.date;
            this.item.time = item.time;
            this.item.moodValue = item.moodValue;
            this.item.hint = item.hint;
            if (item.imageName) {
                this.pictureFile = {name: item.imageName};
            }
            this.item.imageData = item.imageData;
            this.item.imageName = item.imageName;
            this.item.color = item.color;
        } finally {
            this.loading = false;
        }
    }

    private showDialog(item: MoodItem, isNew: boolean, readonly: boolean,minMax:{start:{date:string},end:{date:string}}|undefined): void {
        this.setItemValues(item);
        this.setDialogIcon(isNew, readonly);
        this.readonly = readonly;
        if(minMax) {
            const now = new Date();
            if (minMax.start && minMax.start.date) {
                this.minDate = MoodItem.getFormattedDate(new Date(minMax.start.date));
            } else {
                this.minDate = MoodItem.getFormattedDate(now);
            }
            const date = new Date(minMax.end.date);

            if (minMax.end && minMax.end.date) {
                if(date > now) {
                    this.maxDate = MoodItem.getFormattedDate(now);
                } else {
                    this.maxDate = MoodItem.getFormattedDate(date);
                }
            } else {
                this.maxDate = MoodItem.getFormattedDate(now);
            }
        }else {
            this.minDate = '';
            this.maxDate = MoodItem.getFormattedDate(new Date());
        }
        this.isNewEntry = isNew;
        this.openDialog = true;
    }

    private getIcon(): string {
        const moodValue = this.item.moodValue;
        const moodType = this.$store.getters.diarySettings.moodType;
        if(moodType === MoodType.Mood5) {
            if (moodValue === 5) return 'mdi-emoticon-excited';
            else if (moodValue === 4) return 'mdi-emoticon-happy';
            else if (moodValue === 3) return 'mdi-emoticon-neutral'
            else if (moodValue === 2) return 'mdi-emoticon-sad'
            else return 'mdi-emoticon-cry';
        } else if (moodType === MoodType.Mood3) {
            if (moodValue > 3) return 'mdi-emoticon-happy';
            else if (moodValue === 3) return 'mdi-emoticon-neutral'
            else return 'mdi-emoticon-sad'
        }else {
            if (moodValue >= 3) return 'mdi-thumb-up';
            else return 'mdi-thumb-down';
        }
    }

    private closeDialog(): void {
        this.openDialog = false;
    }

    private deleteItem(): void {
        this.$emit("deleteItem", {item: this.item});
        this.closeDialog();
    }
    private saveItem(): void {
        this.$emit("saveItem", {item: this.item, isNew: this.isNewEntry});
        this.closeDialog();
    }

    private setDialogIcon(isNew: boolean, readonly: boolean) {
        if (isNew) {
            this.dialogIcon = 'mdi-plus-circle-outline';
        } else {
            if (readonly) {
                this.dialogIcon = 'mdi-eye';
            } else {
                this.dialogIcon = 'mdi-pencil'
            }
        }
    }
    private moodChanged() : void {
        this.item.color = this.getColor(this.item.moodValue);
    }
    private switchToEdit(): void {
        this.readonly = false;
    }
    private setTimeNow(): void {
        this.item.time = moment(new Date()).format("HH:mm");
        //@ts-ignore
        this.$refs.timeMenu.save(this.item.time);
    }
    private getValue(oldValue:number): number {
        if (oldValue > 0) {
            const moodType = this.$store.getters.diarySettings.moodType
            if(moodType === MoodType.Mood3) {
                if(oldValue === 5) {
                    return 4;
                }
                if(oldValue === 1) {
                    return 2;
                }
            }
            if(moodType === MoodType.Thumb) {
                if ( oldValue >= 3) {
                    return  4;
                }
                return  2;
            }
        }
        return oldValue;
    }
    private get moodSelectComponent() {
        const diarySettings = this.$store.getters.diarySettings;
        if(diarySettings.moodType === MoodType.Mood5 || diarySettings.moodType === MoodType.Mood3) {
            return () => {
                return import("@/components/moodary/MoodSelect.vue");
            };
        }
        if(diarySettings.moodType === MoodType.Thumb ) {
            return () => {
                return import("@/components/moodary/ThumbSelect.vue");
            };
        }

        return () => {
            return import("@/components/moodary/MoodSelect.vue");
        };
    }
}
