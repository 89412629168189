
































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import MoodItem from "@/models/MoodItem";
import DateUtility from "@/assets/utils/DateFormatUtil";
import moment from "moment-mini";

@Component({components: {}})
export default class WeekDatePicker extends Vue {

    @Prop({required: true})
    public readonly value!: string;

    private weekRange: string[] = DateUtility.getCurrentWeekDates();
    private formattedDate: string = '';
    private dateMenu: boolean = false;

    private get firstDayOfWeek(): number {
        return Number.parseInt(this.$tc('general.firstDayOfWeek'));
    }

    @Watch("value")
    openOnChange() {
        let date = new Date();
        if (this.value) {
            date = new Date(this.value);
        }

        const dateRange = DateUtility.getCurrentWeekDates(date);
        this.setFormattedDate(dateRange[0]);
        this.weekRange[0] = dateRange[0];
        this.weekRange[1] = dateRange[1];
    }

    private weekRangeChanged(newValue: string[], oldValue: string[]) {
        this.dateMenu = false;
        if (this.weekRange[newValue.length - 1] !== this.value) {
            this.$emit("input", this.weekRange[newValue.length - 1]);
        } else {
            this.openOnChange();
        }
    }

    constructor() {
        super();
    }

    private mounted() {
        this.openOnChange();
    }

    //TODO: get calendar week - localisation
    private setFormattedDate(startDate: string) {
        const date = moment(startDate);
        this.formattedDate = this.$t('trends.dateRangeCompareDialog.calendarWeek', {
            week: (date.isoWeek() + 1) - this.firstDayOfWeek,
            year: date.isoWeekYear()
        }).toString();
    }

    private get maxDate(): string {
        return MoodItem.getFormattedDate(new Date());
    }

    private clearDateValue() {
        this.weekRange = DateUtility.getCurrentWeekDates();
        this.$emit("input", this.weekRange[0]);
    }

}
